<template>
  <div>
    <el-container
      style="
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
      "
    >
      <el-header>
        <div class="left-box">
          <img src="../../assets/images/logo.png" />
          <span>后台管理系统</span>
        </div>
        <div class="right-box">
          <span>{{ nickname }}</span>
          <i class="el-icon-switch-button" @click="logOut" />
        </div>
      </el-header>
      <el-container style="height: 100%">
        <!-- 侧边布局 -->
        <el-aside width="112px">
          <el-menu
            :default-active="currentSelect.toString()"
            background-color="rgba(44, 46, 58, 1)"
            text-color="rgba(255, 255, 255, 100)"
            @select="handleSelect"
          >
            <el-menu-item index="0">
              <img src="../../assets/images/icon01.png" />
              <div style="margin-top: -20px">首页</div>
            </el-menu-item>
            <el-menu-item index="1">
              <img src="../../assets/images/icon02.png" />
              <div style="margin-top: -20px">老兵管理</div>
            </el-menu-item>
            <el-menu-item index="2">
              <img src="../../assets/images/icon03.png" />
              <div style="margin-top: -20px">勋章管理</div>
            </el-menu-item>
            <el-menu-item index="3">
              <img src="../../assets/images/icon04.png" />
              <div style="margin-top: -20px">轮播资讯</div>
            </el-menu-item>
            <el-menu-item index="4">
              <img src="../../assets/images/icon07.png" />
              <div style="margin-top: -20px">老兵筹</div>
            </el-menu-item>
            <el-menu-item index="5">
              <img src="../../assets/images/icon05.png" />
              <div style="margin-top: -20px">用户管理</div>
            </el-menu-item>
            <el-menu-item index="6">
              <img src="../../assets/images/icon06.png" />
              <div style="margin-top: -20px">管理员管理</div>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSelect: 0,
      pagePath: [
        { path: '/index' },
        { path: '/veteran' },
        { path: '/medalManage' },
        { path: '/carousel' },
        { path: '/money-collecting' },
        { path: '/userManage' },
        { path: '/adminManage' },
      ],
      nickname: '',
    }
  },
  created() {
    const currentPath = '/' + this.$route.fullPath.split('/')[1]

    console.log('currentPath', currentPath)

    this.pagePath.forEach((value, index) => {
      if (value.path === currentPath) {
        this.currentSelect = index
        return
      }
    })
    this.nickname = sessionStorage.nickname
  },
  methods: {
    handleSelect(index) {
      const { $router, $route, pagePath } = this
      if ('/' + $route.path.split('/')[1] === pagePath[index].path) {
        return
      }
      $router.push(pagePath[index])
    },
    // 退出系统
    logOut() {
      this.$confirm('是否确认退出系统？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          localStorage.clear()
          sessionStorage.clear()
          this.$router.push('/login')
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.el-header {
  background-color: rgba(56, 61, 74, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-box {
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      color: #fff;
      margin-left: 60px;
    }
  }
  .right-box {
    font-size: 16px;
    color: #fff;
    span {
      margin-right: 16px;
    }
  }
}
.el-aside {
  background-color: rgba(44, 46, 58, 1);
  /* color: #333; */
  .aside-title {
    font-size: 12px;
  }
}
.el-main {
  /* background-color: #E9EEF3; */
  color: #333;
}
.el-menu-item {
  width: 112px;
  height: 84px;
  text-align: center;
  border-left: 3px solid #333;
}
.el-menu-item.is-active {
  background-color: #2075ff !important;
  color: #fff;
  border-left: 3px solid #fff;
}
</style>
